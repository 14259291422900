.data-list {
	ul {
		list-style: none;
	}
	li {
		position: relative;
	    padding: 0.75rem 1.25rem;
	    border: 1px solid transparent;
	        border-top-color: transparent;
	        border-right-color: transparent;
	        border-bottom-color: transparent;
	        border-bottom-style: solid;
	        border-bottom-width: 1px;
	        border-left-color: transparent;
	    border-radius: 0.25rem;

		color: #0c5460;
		background-color: #d1ecf1;
		border-color: #bee5eb;

		a{
			color : #000000;
		}
	}
	li.focus-list {
		background-color: #58ACFA !important;
	}
}


.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
	display:block;
  &__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  &__dialog {
    background-color: #ffffff;
    position: relative;
    width: 600px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    z-index: 2;
    @media screen and (max-width: 992px) {
      width: 90%;
    }
  }
  &__close {
    width: 30px;
    height: 30px;
  }
  &__header {
    padding: 20px 20px 10px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__body {
    padding: 10px 20px 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  &__footer {
    padding: 10px 20px 20px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
